<!-- 
@Author ——ZGM
@Date ——2022年
-->
<template>
  <div class="LinK">
    <!-- 顶部banner -->
    <div class="topBanner">

      <div class="list">
        <div class="left contain">
          <div class="top">
            <el-card :body-style="{ padding: '0px' }">
              <img :src="require(`../../assets/images/aboutLink/Link_2.jpg`)" class="image">
            </el-card>
          </div>
          <div class="bottom">
            <el-card :body-style="{ padding: '0px' }">
              <img :src="require(`../../assets/images/aboutLink/Link_3.jpg`)" class="image">
            </el-card>
          </div>
        </div>
        <div class="center contain">
          <el-card :body-style="{ padding: '0px' }">
            <img :src="require(`../../assets/images/aboutLink/Link_1.jpg`)" class="image">
          </el-card>
        </div>
        <div class="right">
          <div class="top">
            <el-card :body-style="{ padding: '0px' }">
              <img :src="require(`../../assets/images/aboutLink/Link_5.jpg`)" class="image">
            </el-card>
          </div>
          <div class="bottom">
            <el-card :body-style="{ padding: '0px' }">
              <img :src="require(`../../assets/images/aboutLink/Link_6.jpg`)" class="image">
            </el-card>
          </div>
        </div>
      </div>

      <div class="list_2">
        <el-card :body-style="{ padding: '0px' }" v-for="(item,index) in 10" :key="index">
          <img :src="require(`../../assets/images/aboutLink/Link_${index+7}.jpg`)" class="image">
        </el-card>
      </div>

      <!-- <ul>

        <li v-for="(item, index) in 4" :key="index">
          <el-card :body-style="{ padding: '0px' }">
            <img :src="require(`../../assets/images/aboutLink/Link_${index + 1}.jpg`)" class="image">
          </el-card>
        </li>
      </ul> -->


    </div>

    <!-- 中间 -->
    <div class="center">
      <div class="centerContent">
        <div class="left" ref="centerLeft" :class="{ centerLeft: isLeft }">
          <div class="title">
            <p :class="{ moveP: isLeft }">关于领氪</p>
          </div>
          <div class="paragraphOne">
            成都领氪科技有限公司是一家智慧城市、公用事业物联网及智能化方案提供商，致力于推动AIoT技术在城市及公用事业的应用与落地，以帮助政府、市政及公用企事业单位提升公共资源的管控能力和智能化水平。公司聚焦水务、燃气、电力等市政与公用事业领域，提供从智能设备、大数据、AIoT应用开发到智能化运维的产品和服务。
          </div>

          <div class="paragraphOne">
            公司聚集了一批来自通信互联网、物联网与智慧城市的资深专家，自主研发了基于产业互联网架构的iLink©平台，通过连接设备厂商、城市市政与公用企业和终端用户，打通行业壁垒，构建共享、共用、共赢的产业生态和商业闭环；并通过“大数据+AIoT”赋能智慧城市，助力智能化、数字化转型升级。
          </div>
        </div>
        <div class="right" ref="centerRight" :class="{ centerRight: isRight }">
          <img src="../../assets/images/aboutLink/company.jpg" alt="#" />
        </div>
      </div>

      <!-- 左边覆盖 -->
      <!-- <div class="leftCover"></div>
    
      <div class="rightCover"></div> -->
    </div>

    <!-- bottomContent -->
    <div class="bottomContent" ref="moveImg">
      <!-- :class="{ moveImg: isImg }" -->
      <div class="imgContainer" :class="{ moveImg: isImg }">
        <img src="../../assets/images/aboutLink/company2.png" alt="" />
      </div>
      <!-- <div class="imgCover"></div> -->
    </div>

    <!-- 子哦地步 -->
    <div class="bottomSection">
      <div class="title">
        <p :class="{ moveTitle: isBottom }">核心能力</p>
      </div>
      <div class="fnContainer">
        <div class="left" :class="{ moveLeft: isBottom }">
          <img src="../../assets/images/aboutLink/company3.jpg" alt="" />
        </div>
        <div class="right" :class="{ moveRight: isBottom }">
          <ul>
            <li>
              <div class="liLeft_1">
                <img src="../../assets/images/aboutLink/ok.png" alt="" />
              </div>
              <div class="liRightt">
                <div class="rightTitle">
                  <p><b>全链条智慧公用解决方案和实施能力</b></p>
                  <p>
                    从信息系统、智能设备到工程建设，我们可以提供从规划、设计到工程实施全要素、全链条的解决方案和服务。
                  </p>
                </div>
              </div>
            </li>

            <li>
              <div class="liLeft_1">
                <img src="../../assets/images/aboutLink/ok.png" alt="" />
              </div>
              <div class="liRightt">
                <div class="rightTitle">
                  <p><b>一流的市政工程设计咨询能力</b></p>
                  <p>
                    联合国内一流的设计、咨询机构，能为客户提供项目前期的咨询、可研、初设等工作,并为客户提供最佳行业实践参考。
                  </p>
                </div>
              </div>
            </li>

            <li>
              <div class="liLeft_1">
                <img src="../../assets/images/aboutLink/ok.png" alt="" />
              </div>
              <div class="liRightt">
                <div class="rightTitle">
                  <p><b>强大的行业设备整合能力</b></p>
                  <p>
                    与国内外一流设备厂商建立了良好的合作基础，并能得到主流设备原厂的商务支持及技术支持，为客户解决后顾之忧。,并为客户提供最佳行业实践参考。
                  </p>
                </div>
              </div>
            </li>

            <li>
              <div class="liLeft_1">
                <img src="../../assets/images/aboutLink/ok.png" alt="" />
              </div>
              <div class="liRightt">
                <div class="rightTitle">
                  <p><b>全栈物联网软件开发和个性化定制能力</b></p>
                  <p>
                    公司具有物联网、大数据、Al、移动APP、嵌入式应用等软件开发能力，能提供从成熟软件产品到个性化定制的软件开发服务。,并为客户提供最佳行业实践参考。
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 覆盖 -->
      <!-- <div class="coloverLeft"></div>
      <div class="coloverRight"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLeft: false,
      isRight: false,
      firstTop: null,
      isImg: false,
      secondTop: null,
      isBottom: false,
    };
  },
  watch: {},
  computed: {},
  created() { },
  mounted() {
    this.getTop();
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取各元素距离顶部的距离
    getTop() {
      this.firstTop = this.$refs.centerLeft.getBoundingClientRect().top;
      this.secondTop = this.$refs.moveImg.getBoundingClientRect().top;
    },
    // 元素滚动监听
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量

      if (scrollTop == 0 || (scrollTop < 1020 && !this.isLeft)) {
        this.isLeft = true;
        this.isRight = true;
      }

      if (
        (scrollTop > 302 && scrollTop < 400 && !this.isImg) ||
        (scrollTop < 1780 && scrollTop > 400 && !this.isImg)
      ) {
        this.isImg = true;
      }
      if (scrollTop >= 1115 && scrollTop <= 2134 && !this.isBottom) {
        this.isBottom = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.LinK {
  width: 100%;

  .topBanner {
    // height: 500px;
    // background: url("../../assets/images/aboutLink/LinkBanner.jpg") no-repeat
    //   center;
    // background-size: cover;
    // border: 1px solid red;
    // padding: 20px;
    box-sizing: border-box;
    // border: 1px solid red;

    .list {
      display: flex;
      justify-content: center;
 
      .contain {
        // border: 1px solid red;
        width: 20vw;
        height: 40vh;
      }

      .center {
        width: 40vw;
        height: 40vh;

        img {
          width: 40vw;
          height: 40vh;
        }
      }

      .left {

        .top,
        .bottom {
          width: 20vw;
          height: 20vh;

          img {
            width: 20vw;
            height: 20vh;
          }
        }

        ;
      }

      .right {
        display: flex;

        .top,.bottom {
          img {
            width: 12vw;
            height: 40vh;
          }
        }
      }

    }

    .list_2 {
      display: flex;

      img {
        width: 10vw;
         height: 30vh;
      }
    }

    // ul {
    //   list-style: none;

    //   display: flex;
    //   justify-content: space-around;

    //   li {
    //     border: 1px solid green;
    //     width: 22vw;
    //     height: 52vh;
    //     // margin-right: 50px;

    //     .image {
    //       width: 22vw;
    //       height: 52vh;
    //     }
    //   }
    // }
  }

  .center {
    height: 622px;
    position: relative;
    overflow: hidden;

    .centerContent {
      height: 622px;
      width: 1200px;
      margin: 0 auto;
      padding-top: 120px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow: hidden;

      .left {
        width: 522px;
        position: absolute;
        left: -522px;

        .title {
          color: #1f87e8;
          font-size: 30px;
          font-family: "Microsoft YaHei";
          text-align: center;
          margin-bottom: 40px;
          height: 44px;
          position: relative;
          overflow: hidden;

          p {
            position: absolute;
            left: -600px;
            width: 100%;
            text-align: center;
          }
        }

        .paragraphOne {
          text-indent: 2em;
          color: #666;
          font-size: 16px;
          font-family: "Microsoft YaHei";
          margin-bottom: 24px;
        }
      }

      .right {
        width: 393px;
        height: 384px;
        position: absolute;
        right: -393px;

        img {
          width: 393px;
          height: 384px;
        }
      }
    }

    .leftCover {
      height: 622px;
      position: absolute;
      width: 352px;
      top: 0;
      z-index: 999;
      background: #fff;
    }

    .rightCover {
      height: 622px;
      position: absolute;
      width: 352px;
      top: 0;
      right: 0;
      z-index: 999;
      background: #fff;
    }
  }

  .bottomContent {
    height: 702px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: #284563;
    box-sizing: border-box;
    padding-top: 100px;

    .imgContainer {
      height: 70.4%;
      width: 1046px;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;

      img {
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 100%;
      }
    }

    // .imgCover {
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   height: 702px;
    //   width: 22em;
    //   background: #284563;
    //   z-index: 999;

    // }
  }

  .bottomSection {
    height: 630px;
    box-sizing: border-box;
    padding-top: 80px;
    position: relative;
    overflow: hidden;

    .title {
      font-size: 30px;
      color: #4b4b4b;
      font-family: "Microsoft YaHei";
      text-align: center;
      position: relative;
      overflow: hidden;
      height: 50px;

      >p {
        position: absolute;
        width: 100%;
        position: absolute;
        right: -510px;
      }
    }

    .fnContainer {
      width: 1200px;
      height: 450px;
      margin: 0 auto;
      margin-top: 58px;
      display: flex;
      justify-content: space-around;
      position: relative;
      overflow: hidden;

      .left {
        width: 526px;
        height: 252px;
        position: absolute;
        left: -500px;

        img {
          width: 526px;
          height: 252px;
        }
      }

      .right {
        width: 510px;
        height: 326px;
        position: absolute;
        right: -510px;

        ul {
          list-style: none;

          li {
            display: flex;
            margin-bottom: 30px;

            .liLeft_1 {
              background: #77a75a;
              height: 24px;
              width: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 3px;

              margin-top: 4px;

              img {
                width: 18px;
                height: 18px;
                margin: 6px;
              }
            }

            .rightTitle {
              margin-left: 10px;

              p:first-child {
                padding: 0;
                font-size: 14px;
                margin-bottom: 5px;
                color: #4a4b48;
              }

              p:last-child {
                font-size: 12px;
                color: #4a4b40;
              }
            }
          }
        }
      }
    }

    .coloverLeft {
      background: #fff;
      height: 630px;
      width: 352px;
      position: absolute;
      left: 0;
      top: 0;
    }

    .coloverRight {
      background: #fff;
      height: 630px;
      width: 352px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>

<style lang="less" scoped>
.centerLeft {
  animation: left 1s ease forwards;
}

@keyframes left {
  from {
    left: -522px;
  }

  to {
    left: 0;
  }
}

.centerRight {
  animation: right 1.2s ease forwards;
}

@keyframes right {
  from {
    right: -393px;
  }

  to {
    right: 0;
  }
}

.moveP {
  animation: targetP 0.8s ease forwards;
}

@keyframes targetP {
  from {
    left: -600px;
  }

  to {
    left: 0;
  }
}

.moveImg {
  animation: moveImage 1s ease forwards;
}

@keyframes moveImage {
  from {
    left: -850px;
  }

  to {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.moveTitle {
  animation: moveT 1s ease forwards;
}

@keyframes moveT {
  from {
    right: -510px;
  }

  to {
    right: 0px;
  }
}

.moveLeft {
  animation: moveT_1 1s ease forwards;
}

@keyframes moveT_1 {
  from {
    left: -500px;
  }

  to {
    left: 0px;
  }
}

.moveRight {
  animation: moveT_2 1s ease forwards;
}

@keyframes moveT_2 {
  from {
    right: -510px;
  }

  to {
    right: 0;
  }
}
</style>
